<template>
  <div v-frag>
    <v-card id="login">     
      <v-img id="logo-mida4" v-if="showHeader" src="@/assets/logoMida4Ext.png" alt="Logo Mida4"></v-img>    
      <v-card-title v-if="showHeader" id="header">Spese Sanitarie</v-card-title> 
      <FormRichiediResetSpSa v-if="isRichiediReset == true"
        :messages="messages"
        :formLogin="true"
        :richiestaInviata="richiestaResetInviata" 
        @reset="reset"
        @richiediReset="toggleRichiediReset"/>
      <FormLoginSpSa v-else
        :messages="messages" 
        @login="login"
        @richiediReset="toggleRichiediReset" />
    </v-card>  
    <v-alert v-if="errore!=''" type="error">
      {{errore}}
    </v-alert>  
  </div>
</template>

<script>

const NAME = 'LoginSpSa'

import FormRichiediResetSpSa from '@/components/LoginSpSa/FormRichiediResetSpSa'
import FormLoginSpSa from '@/components/LoginSpSa/FormLoginSpSa'

export default {
  name: NAME,
  props: ['messages', 'richiestaResetInviata'],
  data(){
    return { 
      NAME: NAME,
      isRichiediReset: false,
      errore: '',
      showHeader: true,
    }
  },
  components: {
    FormRichiediResetSpSa, 
    FormLoginSpSa
  },
  methods: {    
    toggleRichiediReset(val){
      this.isRichiediReset = val
    },
    reset(args) {
      this.$emit('reset', args)
    },
    login(args) {
      this.$emit('login', args)
    }
  }
}
</script>