<template>
  <div v-frag>
    <v-card-text id="credenziali"
      ><v-text-field
        v-model="credenziali.username"
        label="Codice Fiscale"
        name="codiceFiscale"
        counter
        maxlength="16"
        value=""
        rounded
        :rules="[rules.requiredUserName]"
        @keyup.13.prevent="emitLogin"
      />
      <v-text-field
        v-model="credenziali.password"
        label="Password"
        name="password"
        value=""
        :append-icon="showPwd ? '$eyeSlash' : '$eye'"
        @click:append="() => (showPwd = !showPwd)"
        rounded
        :rules="[rules.requiredPassword]"
        :type="showPwd ? 'text' : 'password'"
        @keyup.13.prevent="emitLogin"
      />
    </v-card-text>
    <v-card-actions id="accedi">
      <v-btn text rounded @click.prevent="emitLogin">LOGIN</v-btn>
    </v-card-actions>
    <div id="login-redirects">
      <a class="login-redirects" href="#" @click="$emit('richiediReset', true)"
        >Password dimenticata ?</a
      >
      <a class="login-redirects" href="#/registrazione">Nuovo utente ?</a>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
const NAME = "FormLoginSpSa";
const CAMPO_OBBLIGATORIO = "Campo obbligatorio";

import { R, Forms } from "mida4-web-app-utils";

export default {
  name: NAME,
  props: ["messages"],
  data() {
    return {
      NAME: NAME,
      credenziali: {
        username: "",
        password: "",
      },
      showPwd: false,
      errore: "",
      rules: {
        requiredUserName: (v) => !!v || this.msgs.requiredUserName,
        requiredPassword: (v) => !!v || this.msgs.requiredPassword,
      },
    };
  },
  computed: {
    msgs() {
      return {
        requiredUserName: this.messages.requiredUserName || CAMPO_OBBLIGATORIO,
        requiredPassword: this.messages.requiredPassword || CAMPO_OBBLIGATORIO,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.testAutoFill();
      document
        .querySelector("[name=codiceFiscale]")
        .addEventListener("input", Forms.evTargetToUpper);
      document
        .querySelector("[name=codiceFiscale]")
        .addEventListener("blur", Forms.evTargetToUpper);
    });
  },
  methods: {
    emitLogin() {
      if (
        Object.values(this.credenziali).some((i) => R.isNil(i) || R.isEmpty(i))
      ) {
        return false;
      } else {
        this.$emit("login", {
          ...this.credenziali,
          username: this.credenziali.username.toUpperCase(),
        });
      }
    },
    async testAutoFill() {
      if (
        await Forms.detectAutofill(
          document.querySelector("[name=codiceFiscale")
        )
      ) {
        document
          .querySelectorAll("#credenziali .v-input label")
          .forEach((el) => {
            el.style.transform = "translateY(-1.5rem) scale(0.75)";
          });
      }
    },
  },
};
</script>
<style src="@/styles/custom/components/login/_form_login.scss" lang="scss"></style>