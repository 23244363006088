<template>
  <LayoutSpSa>
    <LoginSpSa
      :messages="{
        requiredUserName: 'Inserire il codice fiscale.',
        requiredPassword: 'Inserire la password.',
      }"
      :richiestaResetInviata="richiestaResetInviata"
      @login="login"
      @reset="reset"
    />
  </LayoutSpSa>
</template>
<script>
const NAME = "LoginViewSpSa";

import { REGEXES } from "mida4-web-app-utils";
import { mapGetters, mapActions } from "vuex";

import LayoutSpSa from "@/components/_layout/LayoutSpSa";
import LoginSpSa from "@/components/LoginSpSa/LoginSpSa";

export default {
  name: NAME,
  data() {
    return {
      NAME: NAME,
      richiestaResetInviata: false,
      errore: "",
      showHeader: true,
    };
  },
  components: {
    LayoutSpSa,
    LoginSpSa,
  },
  computed: {
    ...mapGetters({
      daVerificare: "user/daVerificare",
    }),
  },
  methods: {
    ...mapActions({
      doLogin: "auth/login",
      avviaReset: "user/richiediResetPassword",
    }),
    async reset(cf) {
      this.toggleLoader(true);
      try {
        await this.avviaReset(cf);
        this.richiestaResetInviata = true;
        this.toggleLoader(false);
      } catch (e) {
        this.toggleLoader(false);
        this.showLoginFormError({
          title: "Errore di rete",
          message: `Non è stato possibile contattare il server. Controllare la connessione - ${e.message}`,
        });
      }
    },
    async login(credenziali) {
      this.toggleLoader(true);
      // invio i dati
      try {
        await this.doLogin(credenziali);
        this.toggleLoader(false);
        // redirect root path
        this.$router.push("/");
      } catch (e) {
        this.toggleLoader(false);
        this.showLoginFormError(
          REGEXES.ERR_INTERNO_LOGIN.test(e.message)
            ? {
                title: "Errore interno",
                message: `Si è verificato un errore, contattare l'assistenza - ${e.message}`,
              }
            : {
                title: "Errore login",
                message: `Il server ha restituito un errore: ${e.message}`,
              }
        );
      }
    },
  },
  notifications: {
    showLoginFormError: {
      title: "Errore",
      message: "Si è verificato un errore imprevisto",
      type: "error",
    },
  },
};
</script>
<style src="@/styles/custom/views/_login.scss" lang="scss"></style>
